
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import inv, { Invoice } from '@/shared/model/invoice';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';
import { documentTypeList } from '@/shared/model/smallPayloadModels/documentType';
import DateField from '@/components/_common/date-field/DateField.vue';
import DocumentPreview from '../../document-preview/document-preview.vue';
import html2canvas from 'html2canvas';
import GeneralUtils from '@/shared/utils/generalUtils';
import SupplierTooltip from '../../zr-documents/search-form/supplier-tooltip/supplier-tooltip.vue';

const name = 'invoice';
const logger = new Logger(name);
const invoiceModule = namespace('invoice');
const authModule = namespace('auth');

const delay = GeneralUtils.delay; // delay function

@Component({
  name: name,
  components: {
    D4yEdit,
    DateField,
    DocumentPreview,
    SupplierTooltip,
  },
})
export default class InvoiceFormComponent extends Vue {
  @Ref('observer-edit-invoice-form')
  private editInvoiceForm!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroup!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserSuperAdmin')
  private isUserSuperAdmin!: any;
  @authModule.Getter('isUserOnlyGsAndHasTestZr')
  private isUserOnlyGsAndHasTestZr!: any;
  @authModule.Getter('isUserHasRightsForInvoices')
  private isUserHasRightsForInvoices!: any;

  @invoiceModule.Action('getInvoice')
  private actionGetInvoice!: any;
  @invoiceModule.Action('updateInvoice')
  private actionUpdateInvoice!: any;
  @invoiceModule.Getter('getInvoice')
  private getInvoice!: any;
  @invoiceModule.Getter('getInvoiceItems')
  private invoices!: Array<any>;
  @invoiceModule.Action('getInvoicesDetailsPdfReport')
  private actionGetInvoicesDetailsPdfReport!: any;

  private isRecordLoaded = false;
  private isShowBackupRechnungImage = false;
  private isGeneratingPdfReport = false;

  private belegeType = 'Rechnung'; // !! type should `Rechnung` since different logic on backend for `Rechnung` type for downloading file

  get invoiceId() {
    return this.$route.params['invoiceId'];
  }

  get isInvoiceCreate() {
    return this.invoiceId == CONST.emptyGuid || !this.invoiceId;
  }

  async mounted() {
    //GSP-097 temp redirect will be deleted later
    // GSP-107 from 2024-01-24 allow to see `Invoices` for all users (this limitation can be brought back, so commented, not deleted )
    // if (!this.isUserSuperAdmin && !this.isUserOnlyGsAndHasTestZr) {
    //   this.goToZrDocumentsList();
    // }

    if ((!this.isUserInAzureAdminGroup && !this.isUserGsUser) || !this.isUserHasRightsForInvoices) {
      this.goToInvoiceList();
    }

    if (this.isInvoiceCreate) {
      logger.error(`Creation of 'Invoice' is not supported!`);
    } else {
      await this.loadInvoice();
    }
  }

  showBackupImage(documentType: string) {
    this.isShowBackupRechnungImage = true;
  }

  loadInvoice() {
    this.isRecordLoaded = false; // change loading flag when change `Invoice` via `next/previous` buttons

    this.actionGetInvoice(this.invoiceId)
      .then((response: any) => {
        console.log('get Invoice response :>> ', response);
        this.invoice = Object.assign({}, response);

        // console.log('windows.location :>> ', window.location);
        history.pushState({}, '', window.location.href.replace('0?doc', `${this.invoice.id}?doc`));
        // window.location.href = window.location.href.replace('0?doc',`${this.invoice.id}?doc`);
      })
      .then(() => {
        this.isRecordLoaded = true;
      });
  }
  private invoice: Invoice = inv.parse({});

  isLoading = false;
  titleInfo = 'empty';

  get isEmptySaveList() {
    return this.invoice.id == '';
  }

  get image() {
    return {
      src: 'gs_details.svg',
      header: '',
      width: 230,
      height: 210,
    };
  }

  // private imageReceiptVar: {
  //   src: string;
  //   header?: string;
  //   height: number;
  //   width: number;
  //   small?: boolean;
  // } = {
  //   src: 'belege_stub_big.png',
  //   height: this.dynamicImageSize.height,
  //   width: this.dynamicImageSize.width,
  // };

  get imageReceipt() {
    return {
      src: 'belege_stub_big.png',
      height: this.dynamicImageSize.height,
      width: this.dynamicImageSize.width,
    };
  }

  get dynamicImageSize() {
    if (this.$vuetify.breakpoint.xlOnly) {
      return { height: 610, width: 450 };
    } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width > 1580) {
      return { height: 610, width: 450 };
    } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width < 1580) {
      return { height: 600, width: 410 };
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return { height: 410, width: 250 };
    }

    return { height: 515, width: 350 };
  }

  get notices() {
    let listInvoices: any = this.invoice?.invoices.map((element: any) => ({
      ...element,
      belegTyp: this.documentTypeFullName(element.belegTypId)
    }));
    return listInvoices;
  }

  documentTypeFullName(belegtypId: number) {
    let i18key = documentTypeList.find((x) => x.index == belegtypId)?.i18nKey;
    let formattedResult = i18key ? `${this.$t(i18key)} (${belegtypId})` : belegtypId;
    return formattedResult;
  }

  get hasRecords() {
    return this.invoice?.invoices?.length > 0;
  }

  get noticeHeaders() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('busy_type'), value: 'belegTyp', sortable: false, width: '225' },
      { text: this.$t('date'), value: 'datumInvoice', sortable: false, width: '85', align: 'center' },
      { text: this.$t('gross_amount'), value: 'bruttobetrag', sortable: false, width: '75', align: 'right' },
    ];
    return headers;
  }

  get documentTypeValue() {
    let i18key = documentTypeList.find((x) => x.index == this.invoice.belegtyp)?.i18nKey;
    let formattedResult = i18key ? `${this.$t(i18key)} (${this.invoice.belegtyp})` : this.invoice.belegtyp;
    return formattedResult;
  }

  get betragNetto() {
    return (
      // was this.invoice.netto / 100
      this.invoice.netto.toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + ' ' + this.getCurrencySymbol(this.invoice.waehrung.toLowerCase())
    );
  }

  get taxAmount() {
    return (
      // was this.invoice.mwst_betrag / 100
      this.invoice.mwst_betrag.toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + ' ' + this.getCurrencySymbol(this.invoice.waehrung.toLowerCase())
    );
  }

  get localizedBetragBrutto() {
    return (
      // was this.invoice.brutto / 100
      this.invoice.brutto.toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + ' ' + this.getCurrencySymbol(this.invoice.waehrung.toLowerCase())
    );
  }

  // (GSP-136) invoice `discount` fields were deleted from database
  // zero_num = 0;
  // get localizedTotalAbzug() {
  //   // (GSP-136) field was deleted this.invoice.discount_rate
  //   return (
  //     this.zero_num.toLocaleString(this.$i18n.locale, {
  //       maximumFractionDigits: 2,
  //       minimumFractionDigits: 2,
  //     }) + ' %'
  //   );
  // }
  // get localizedInstantAbzug() {
  //   return (
  //     // (GSP-136) field was deleted this.invoice.discount_amount 
  //     this.zero_num.toLocaleString(this.$i18n.locale, {
  //       maximumFractionDigits: 2,
  //       minimumFractionDigits: 2,
  //     }) + ' €'
  //   );
  // }

  // use `19` for `steursatz` if it is NULL on backend
  get taxRate() {
    return this.invoice.mwst_satz === null || this.invoice.mwst_satz === undefined ? 19 : this.invoice.mwst_satz;
  }

  get localizedTaxRate() {
    return this.taxRate.toLocaleString(this.$i18n.locale) + ' %'; // || (19).toLocaleString(this.$i18n.locale);
  }

  async onClickedUpdate() {
    const result = await this.editInvoiceForm.validate();
    if (result) {
      this.isLoading = true;

      this.invoice.id = this.invoiceId;
      await this.actionUpdateInvoice(this.invoice)
        .then((response: any) => {})
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  goToInvoiceList() {
    this.$router.push({
      name: ROUTES.invoices,
    });
  }
  goToZrDocumentsList() {
    this.$router.push({
      name: ROUTES.home,
    });
  }

  //#region Next/previous logic
  get currentIdPosition() {
    return this.invoices.findIndex((x: any) => x.id == this.invoiceId);
  }

  get isFirstInvoice() {
    // console.log('this.invoices :>> ', this.invoices);
    // console.log(
    //   'this.invoices ID :>> ',
    //   this.invoices.findIndex((x: any) => x.id == this.invoiceId)
    // );
    return this.currentIdPosition == 0;
  }

  get isLastInvoice() {
    return this.currentIdPosition == this.invoices.length - 1;
  }

  goToPrev() {
    const prevId = this.invoices[this.currentIdPosition - 1].id;

    this.$router.push({
      name: ROUTES.invoice,
      params: { invoiceId: prevId },
    });
    this.loadInvoice();
  }

  goToNext() {
    const nextId = this.invoices[this.currentIdPosition + 1].id;

    this.$router.push({
      name: ROUTES.invoice,
      params: { invoiceId: nextId },
    });
    this.loadInvoice();
  }

  getTitle(value: string) {
    if (value != null && value != undefined) {
      return this.$t('edited_by') + ':  ' + value + ', ';
    } else {
      return '';
    }
  }

  async pdfDownload() {
    this.isGeneratingPdfReport = true;
    this.changePdfScale();
    delay(1200).then(async (result) => {
      let pdfScreenDataUrl = await this.screenshot();

      delay(1200).then(() => {
        let payload = this.invoice;
        payload.pdfScreen = pdfScreenDataUrl;
        payload.DateTimeTitle =
          this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
        payload.lang = this.$i18n.locale;
        this.actionGetInvoicesDetailsPdfReport(payload).finally(() => {
          this.isGeneratingPdfReport = false;
        });

        delay(500).then(() => {
          this.returnInitialPdfScale();
        });
      });
    });
  }

  //#endregion

  get hasIframeWithDocument() {
    return this.isRecordLoaded && document.querySelector(`iframe#${this.belegeType}`);
  }

  async screenshot() {
    let fstPageDataUrl = '';
    if (!this.hasIframeWithDocument) {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAD0lEQVR42mNkwAIYh7IgAAVVAAuInjI5AAAAAElFTkSuQmCC'; // white transparent square 10x10 pixels (generated here https://png-pixel.com/)
    }

    const iframe1stPage: any = (
      document.querySelector(`iframe#${this.belegeType}`) as any
    ).contentDocument!.querySelector('#viewer > div[data-page-number="1"] .canvasWrapper')!;
    await html2canvas(iframe1stPage, {
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas: any) => {
        // It will return a canvas element
        let image = canvas.toDataURL('image/png', 0.75);
        fstPageDataUrl = image;
      })
      .catch((e: any) => {
        // Handle errors
        console.log(e);
      });
    return fstPageDataUrl;
  }

  private pdfScaleInitialValue = '';
  private pdfScaleElem: any = {};
  changePdfScale() {
    if (!this.hasIframeWithDocument) return;

    const size200percent: any = (document.querySelector(
      `iframe#${this.belegeType}`
    ) as any)!.contentDocument!.querySelector('#scaleSelect')!;
    // console.log('size200percent :>> ', size200percent);
    if (size200percent) {
      this.pdfScaleElem = size200percent;
      this.pdfScaleInitialValue = size200percent.value;

      size200percent.value = 1.5;
      size200percent.dispatchEvent(new Event('change'));
    }
  }
  returnInitialPdfScale() {
    if (!this.hasIframeWithDocument) return;

    this.pdfScaleElem.value = this.pdfScaleInitialValue;
    this.pdfScaleElem.dispatchEvent(new Event('change'));
  }

  getCurrencySymbol(currency: any) {
    let currencySymbol: string = '';
    if (currency == 'euro' || currency == 'eur' || currency == '') {
      currencySymbol = ' €';
    } else if (currency == 'chf') {
      currencySymbol = ' ₣';
    } else if (currency == 'usd') {
      currencySymbol = ' $';
    }

    return currencySymbol;
  }
}
